var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card-actions', {
    attrs: {
      "no-actions": true,
      "title": "List Barang"
    }
  }, [_c('section', {
    staticClass: "d-flex justify-content-between"
  }, [_c('section', [_vm.allowCreate() ? _c('b-button', {
    staticClass: "mb-2 mr-2",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$router.push({
          name: 'route-barang-add'
        });
      }
    }
  }, [_vm._v(" Tambah ")]) : _vm._e(), _vm.allowDelete() && _vm.showMassDelButton ? _c('b-button', {
    staticClass: "mb-2",
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.confirmMassDelete($event);
      }
    }
  }, [_vm._v(" Hapus ")]) : _vm._e()], 1), _c('section', [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.exportBarang($event);
      }
    }
  }, [_vm._v(" Export ")])], 1)]), _c('header', [_c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Per Page",
      "label-for": "perPage",
      "size": "sm"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6",
      "sm": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Filter",
      "label-for": "filterInput",
      "size": "sm"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": "Tekan enter untuk cari",
      "size": "sm"
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getData();
      }
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "variant": "outline-primary",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.getData();
      }
    }
  }, [_vm._v(" Cari ")])], 1)], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Tabel",
      "label-for": "tabel",
      "label-size": "sm"
    }
  }, [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "options": _vm.tabeloption
    },
    model: {
      value: _vm.tabel,
      callback: function callback($$v) {
        _vm.tabel = $$v;
      },
      expression: "tabel"
    }
  })], 1)], 1)], 1)], 1), _c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "items": _vm.items,
      "fields": _vm.fields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(harga_dasarsts)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('b-form-input', {
          attrs: {
            "size": "sm"
          },
          on: {
            "keydown": function keydown($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              $event.preventDefault();
              return _vm.ubahvarian(item);
            }
          },
          model: {
            value: item.varian_item[0].harga_dasar,
            callback: function callback($$v) {
              _vm.$set(item.varian_item[0], "harga_dasar", $$v);
            },
            expression: "item.varian_item[0].harga_dasar"
          }
        })];
      }
    }, {
      key: "cell(harga_jualsts)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.harga_jual && item.harga_jual.length > 0 ? [_c('b-form-input', {
          attrs: {
            "size": "sm"
          },
          on: {
            "keydown": function keydown($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              $event.preventDefault();
              return _vm.ubahhargajual(item);
            }
          },
          model: {
            value: item.harga_jual[0].share_penjual,
            callback: function callback($$v) {
              _vm.$set(item.harga_jual[0], "share_penjual", $$v);
            },
            expression: "item.harga_jual[0].share_penjual"
          }
        })] : _vm._e()];
      }
    }, {
      key: "cell(kodests)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('b-form-input', {
          attrs: {
            "size": "sm"
          },
          on: {
            "keydown": function keydown($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              $event.preventDefault();
              return _vm.ubahvarian(item);
            }
          },
          model: {
            value: item.varian_item[0].kode,
            callback: function callback($$v) {
              _vm.$set(item.varian_item[0], "kode", $$v);
            },
            expression: "item.varian_item[0].kode"
          }
        })];
      }
    }, {
      key: "cell(variansts)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('b-form-input', {
          attrs: {
            "size": "sm"
          },
          on: {
            "keydown": function keydown($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              $event.preventDefault();
              return _vm.ubahvarian(item);
            }
          },
          model: {
            value: item.varian_item[0].varian,
            callback: function callback($$v) {
              _vm.$set(item.varian_item[0], "varian", $$v);
            },
            expression: "item.varian_item[0].varian"
          }
        })];
      }
    }, {
      key: "cell(namasts)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('b-form-input', {
          attrs: {
            "size": "sm"
          },
          on: {
            "keydown": function keydown($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              $event.preventDefault();
              return _vm.ubahnama(item);
            }
          },
          model: {
            value: item.nama,
            callback: function callback($$v) {
              _vm.$set(item, "nama", $$v);
            },
            expression: "item.nama"
          }
        })];
      }
    }, {
      key: "cell(margin)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.harga_jual[0].share_penjual - item.varian_item[0].harga_dasar)) + " ")];
      }
    }, {
      key: "cell(show_detailsts)",
      fn: function fn(row) {
        return [_c('b-form-checkbox', {
          staticClass: "vs-checkbox-con",
          attrs: {
            "plain": ""
          },
          on: {
            "change": row.toggleDetails
          },
          model: {
            value: row.detailsShowing,
            callback: function callback($$v) {
              _vm.$set(row, "detailsShowing", $$v);
            },
            expression: "row.detailsShowing"
          }
        }, [_c('span', {
          staticClass: "vs-checkbox"
        }, [_c('span', {
          staticClass: "vs-checkbox--check"
        }, [_c('i', {
          staticClass: "vs-icon feather icon-check"
        })])]), _c('span', {
          staticClass: "vs-label"
        }, [_vm._v(_vm._s(row.detailsShowing ? "Hide" : "Show"))])])];
      }
    }, {
      key: "cell(show_detail)",
      fn: function fn(row) {
        return [_c('b-form-checkbox', {
          staticClass: "vs-checkbox-con",
          attrs: {
            "plain": ""
          },
          on: {
            "change": row.toggleDetails
          },
          model: {
            value: row.detailsShowing,
            callback: function callback($$v) {
              _vm.$set(row, "detailsShowing", $$v);
            },
            expression: "row.detailsShowing"
          }
        }, [_c('span', {
          staticClass: "vs-checkbox"
        }, [_c('span', {
          staticClass: "vs-checkbox--check"
        }, [_c('i', {
          staticClass: "vs-icon feather icon-check"
        })])]), _c('span', {
          staticClass: "vs-label"
        }, [_vm._v(_vm._s(row.detailsShowing ? "Hide" : "Show"))])])];
      }
    }, {
      key: "row-details",
      fn: function fn(_ref7) {
        var toggleDetails = _ref7.toggleDetails,
          item = _ref7.item;
        return [_vm.tabel == 1 ? _c('b-row', {
          staticClass: "mb-1"
        }, [_c('b-col', [_c('quill-editor', {
          model: {
            value: item.deskripsi,
            callback: function callback($$v) {
              _vm.$set(item, "deskripsi", $$v);
            },
            expression: "item.deskripsi"
          }
        })], 1)], 1) : _c('b-row', {
          staticClass: "mb-1"
        }, [_c('b-col', {
          attrs: {
            "cols": "8"
          }
        }, [_c('b-table', {
          attrs: {
            "fields": [{
              key: 'kode',
              label: 'Kode'
            }, {
              key: 'varian',
              label: 'Varian'
            }, {
              key: 'harga_dasar',
              label: 'Harga Dasar'
            }, {
              key: 'aksi',
              label: '#'
            }],
            "striped": "",
            "small": "",
            "responsive": "",
            "items": item ? item.varian_item : [],
            "table-variant": "primary"
          },
          scopedSlots: _vm._u([_vm.modeedit == true ? {
            key: "cell(harga_dasar)",
            fn: function fn(_ref8) {
              var item = _ref8.item;
              return [_c('b-form-input', {
                attrs: {
                  "size": "sm"
                },
                on: {
                  "keydown": function keydown($event) {
                    if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                    $event.preventDefault();
                    return _vm.ubahvarianitem(item);
                  }
                },
                model: {
                  value: item.harga_dasar,
                  callback: function callback($$v) {
                    _vm.$set(item, "harga_dasar", $$v);
                  },
                  expression: "item.harga_dasar"
                }
              })];
            }
          } : null, _vm.modeedit == true ? {
            key: "cell(kode)",
            fn: function fn(_ref9) {
              var item = _ref9.item;
              return [_c('b-form-input', {
                attrs: {
                  "size": "sm"
                },
                on: {
                  "keydown": function keydown($event) {
                    if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                    $event.preventDefault();
                    return _vm.ubahvarianitem(item);
                  }
                },
                model: {
                  value: item.kode,
                  callback: function callback($$v) {
                    _vm.$set(item, "kode", $$v);
                  },
                  expression: "item.kode"
                }
              })];
            }
          } : null, _vm.modeedit == true ? {
            key: "cell(varian)",
            fn: function fn(_ref10) {
              var item = _ref10.item;
              return [_c('b-form-input', {
                attrs: {
                  "size": "sm"
                },
                on: {
                  "keydown": function keydown($event) {
                    if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                    $event.preventDefault();
                    return _vm.ubahvarianitem(item);
                  }
                },
                model: {
                  value: item.varian,
                  callback: function callback($$v) {
                    _vm.$set(item, "varian", $$v);
                  },
                  expression: "item.varian"
                }
              })];
            }
          } : null, {
            key: "cell(aksi)",
            fn: function fn(_ref11) {
              var item = _ref11.item;
              return [_c('section', {
                staticClass: "d-flex justify-content-end"
              }, [_c('b-button', {
                staticClass: "mr-1",
                attrs: {
                  "id": "edit-btn-".concat(item.barang_id),
                  "size": "sm",
                  "variant": "outline-info",
                  "title": "Atur Harga"
                },
                on: {
                  "click": function click($event) {
                    $event.preventDefault();
                    return _vm.aturharga(item);
                  }
                }
              }, [_vm._v(" Harga Jual ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "1"
          }
        }, [_c('b-form-checkbox', {
          model: {
            value: _vm.modeedit,
            callback: function callback($$v) {
              _vm.modeedit = $$v;
            },
            expression: "modeedit"
          }
        }, [_vm._v(" Mode Edit ")])], 1), _c('b-col', {
          attrs: {
            "cols": "3"
          }
        }, [_c('table', {
          attrs: {
            "border": "3"
          }
        }, [_c('tr', [_c('th', [_vm._v("Satuan Dari")]), _c('th', [_vm._v("Nilai")])]), _vm._l(item.konversi, function (satuan, i) {
          return _c('tr', {
            key: i
          }, [_c('td', [_vm._v("1 " + _vm._s(satuan.satuan ? satuan.satuan.satuan : "-"))]), _c('td', [_vm._v(" " + _vm._s(_vm.formatRupiah(satuan.nilai)) + " ")])]);
        })], 2)])], 1), _vm.tabel == 1 ? _c('b-button', {
          staticClass: "mb-1 mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-success"
          },
          on: {
            "click": function click($event) {
              return _vm.ubahnama(item);
            }
          }
        }, [_vm._v(" Simpan ")]) : _vm._e(), _c('b-button', {
          staticClass: "mb-1",
          attrs: {
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": toggleDetails
          }
        }, [_vm._v(" Hide ")])];
      }
    }, {
      key: "cell(no)",
      fn: function fn(_ref12) {
        var index = _ref12.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(jenis)",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_vm._v(" " + _vm._s(item.jenis == 1 ? "Rokok" : "Non-Rokok") + " ")];
      }
    }, {
      key: "cell(id_satuan)",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_vm._v(" " + _vm._s(item.satuan ? item.satuan.satuan : "-") + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function fn(_ref15) {
        var item = _ref15.item;
        return [_c('section', {
          staticClass: "d-flex justify-content-end"
        }, [_vm.allowUpdate(_vm.$route.meta.module) ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "id": "edit-btn-".concat(item.id),
            "size": "sm",
            "variant": "outline-info",
            "title": "Edit Produk"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.$router.push("/barang/edit/".concat(item.id));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e(), _vm.allowDelete(_vm.$route.meta.module) ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "id": "delete-btn-".concat(item.id),
            "size": "sm",
            "variant": "outline-danger",
            "title": "Hapus Produk"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.deleteSingle(item.id);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1) : _vm._e()], 1)];
      }
    }])
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-lg",
      "size": "lg",
      "title": _vm.itembarang ? _vm.itembarang.varian : '',
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "ok-only": "",
      "centered": "",
      "no-close-on-backdrop": ""
    },
    model: {
      value: _vm.showaturharga,
      callback: function callback($$v) {
        _vm.showaturharga = $$v;
      },
      expression: "showaturharga"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xl": "6",
      "lg": "6",
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" Contoh yang salah ")]), _c('div', {
    staticClass: "alert-body"
  }, [_c('span', [_vm._v("Jangan ada data tipe konsumen dan satuan yang sama "), _c('ol', [_c('li', [_vm._v("Data Tipe Konsumen \"Retail\" dan Satuan \"Pcs\",")]), _c('li', [_vm._v("Data Tipe Konsumen \"Retail\" dan Satuan \"Jasa\",")]), _vm._v(" Untuk Data kedua jangan sampai ada data yg berisikan seperti diatas, harus berbeda satuannya ")])])])])], 1), _c('b-col', {
    attrs: {
      "xl": "6",
      "lg": "6",
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "success",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" Contoh yang benar ")]), _c('div', {
    staticClass: "alert-body"
  }, [_c('span', [_c('ol', [_c('li', [_vm._v("Data Tipe Konsumen \"Retail\" dan Satuan \"Pcs\",")]), _c('li', [_vm._v("Data Tipe Konsumen \"Retail\" dan Satuan \"Sesi\",")])])])])])], 1), _c('b-col', {
    attrs: {
      "xl": "12",
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-button', {
    staticClass: "mb-1",
    attrs: {
      "block": "",
      "variant": "primary",
      "title": "Tambah Harga Jual"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.tambahhargajual();
      }
    }
  }, [_vm._v(" Tambah Harga Jual ")]), _c('b-modal', {
    attrs: {
      "id": "modal-md",
      "size": "md",
      "title": _vm.itembarang ? _vm.itembarang.varian : '',
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "ok-only": "",
      "centered": "",
      "no-close-on-backdrop": ""
    },
    model: {
      value: _vm.kelolahargajual,
      callback: function callback($$v) {
        _vm.kelolahargajual = $$v;
      },
      expression: "kelolahargajual"
    }
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tipe Konsumen",
      "label-for": "h-tipe-konsumen",
      "label-cols-md": "4"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.tipe_konsumen,
      "reduce": function reduce(option) {
        return option.id;
      },
      "label": "nama"
    },
    model: {
      value: _vm.form2.tipe_konsumen_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form2, "tipe_konsumen_id", $$v);
      },
      expression: "form2.tipe_konsumen_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Konversi Satuan",
      "label-for": "h-satuan",
      "label-cols-md": "4"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.optsatuan,
      "reduce": function reduce(option) {
        return option.id;
      },
      "label": "nama"
    },
    model: {
      value: _vm.form2.satuan_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form2, "satuan_id", $$v);
      },
      expression: "form2.satuan_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Payout",
      "label-for": "h-share_jasa",
      "label-cols-md": "4"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "h-share_jasa",
      "placeholder": "Payout"
    },
    model: {
      value: _vm.form2.share_jasa,
      callback: function callback($$v) {
        _vm.$set(_vm.form2, "share_jasa", $$v);
      },
      expression: "form2.share_jasa"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Harga Jual Mitra",
      "label-for": "h-share_penjual",
      "label-cols-md": "4"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "h-share_penjual",
      "placeholder": "Harga Jual Mitra"
    },
    model: {
      value: _vm.form2.share_penjual,
      callback: function callback($$v) {
        _vm.$set(_vm.form2, "share_penjual", $$v);
      },
      expression: "form2.share_penjual"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "offset-md": "4"
    }
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.submithargajual();
      }
    }
  }, [_vm._v(" Submit ")])], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "fields": [{
        key: 'tipe',
        label: 'Tipe Konsumen'
      }, {
        key: 'satuan',
        label: 'Satuan'
      }, {
        key: 'share_jasa',
        label: 'Payout'
      }, {
        key: 'share_penjual',
        label: 'Harga Jual Mitra'
      }, {
        key: 'harga',
        label: 'Harga Jual'
      }, {
        key: 'harga_dasar',
        label: 'Harga Dasar'
      }, {
        key: 'margin2',
        label: 'Margin'
      }, {
        key: 'aksi',
        label: '#'
      }],
      "small": "",
      "hover": "",
      "responsive": "",
      "items": _vm.listhargajual ? _vm.listhargajual : []
    },
    scopedSlots: _vm._u([{
      key: "cell(aksi)",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [_c('section', {
          staticClass: "d-flex justify-content-end"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "id": "edit-btn-".concat(item.id),
            "size": "sm",
            "variant": "outline-danger",
            "title": "Hapus Harga Jual"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.hapushargajual(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1)], 1)];
      }
    }, {
      key: "cell(tipe)",
      fn: function fn(_ref17) {
        var item = _ref17.item,
          index = _ref17.index;
        return [_vm._v(" " + _vm._s(item.tipe ? item.tipe.nama : '-') + " ")];
      }
    }, {
      key: "cell(satuan)",
      fn: function fn(_ref18) {
        var item = _ref18.item,
          index = _ref18.index;
        return [_vm._v(" " + _vm._s(item.satuan ? item.satuan.satuan : '-') + " ")];
      }
    }, {
      key: "cell(share_jasa)",
      fn: function fn(_ref19) {
        var item = _ref19.item,
          index = _ref19.index;
        return [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Mark Up"
          },
          on: {
            "keyup": function keyup($event) {
              _vm.doFormatHarga({
                index: index,
                item: item
              });
              _vm.hargaJual(index, item);
            },
            "keydown": function keydown($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              $event.preventDefault();
              return _vm.ubahharga(item);
            }
          },
          model: {
            value: item.share_jasa,
            callback: function callback($$v) {
              _vm.$set(item, "share_jasa", $$v);
            },
            expression: "item.share_jasa"
          }
        })];
      }
    }, {
      key: "cell(share_penjual)",
      fn: function fn(_ref20) {
        var item = _ref20.item,
          index = _ref20.index;
        return [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Harga Beli Penjual"
          },
          on: {
            "keyup": function keyup($event) {
              _vm.doFormatHarga({
                index: index,
                item: item
              });
              _vm.hargaJual(index, item);
            },
            "keydown": function keydown($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              $event.preventDefault();
              return _vm.ubahharga(item);
            }
          },
          model: {
            value: item.share_penjual,
            callback: function callback($$v) {
              _vm.$set(item, "share_penjual", $$v);
            },
            expression: "item.share_penjual"
          }
        })];
      }
    }, {
      key: "cell(harga_dasar)",
      fn: function fn(_ref21) {
        var item = _ref21.item,
          index = _ref21.index;
        return [_vm._v(" " + _vm._s(item.harga_dasar) + " ")];
      }
    }, {
      key: "cell(margin)",
      fn: function fn(_ref22) {
        var item = _ref22.item,
          index = _ref22.index;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.harga - item.harga_dasar)) + " ")];
      }
    }, {
      key: "cell(margin2)",
      fn: function fn(_ref23) {
        var item = _ref23.item;
        return [_vm._v(" " + _vm._s(_vm.unFormatRupiah(item.harga) - _vm.unFormatRupiah(item.harga_dasar)) + " ")];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }